import header from './header.html';
import Download from './download/download';
import template from './order.html';
import style from './order.less';

export default angular.module('eventix.order',[ Download])
    .config(function($stateProvider, GoogleTagManagerProvider) {
        $stateProvider.state('eventix.order', {
            url: '/status/:order_guid/:status',
            params: {
                status: { // To support links without the status in the url
                    value: null,
                    squash: true
                }
            },
            views: {
                main: {
                    controller: 'OrderStatusController',
                    controllerAs: 'vm',
                    templateUrl: template
                },
                header: {
                    controller: 'OrderStatusHeaderController',
                    controllerAs: 'vm',
                    templateUrl: header
                }
            },
            resolve: /*@ngInject*/{
                order: function(Order, $transition$, $state) {
                    return Order.getPublic($transition$.params().order_guid).then(order => {
                        // Load Tag as early as possisble
                        let gtm = _.get($state.params, 'gtm', 1);

                        if (gtm !== 0 && gtm !== '0' && order.shop.google_tag) {
                            GoogleTagManagerProvider.addContainer(order.shop.google_tag);
                        }

                        return order;
                    });
                }
            }
        });
    })
    .controller('OrderStatusController', function($state, $location, $analytics, Locales, $translate, FB, UIMessages, $q, $scope, $uibModal, ShopAnalytics, order) {
        var vm = this;
        vm.locales = Locales;
        vm.disableFB = FB.disabled;
        vm.setLocale = code => {
            $translate.use(code);
        };

        vm.orderStatus = _.get($location.search(), 'status', 'pending');
        vm.firstName = _.get($location.search(), 'firstName', '');

        vm.totalPaid = _.get($location.search(), 'total_amount', 0)/100;
        vm.currency = _.get($location.search(), 'currency', DEFAULT_CURRENCY);

        ShopAnalytics.conversion(order);
    }).controller('OrderStatusHeaderController', function($state, $location, $analytics, Locales, $translate) {
        var vm = this;
        vm.locales = Locales;
        vm.setLocale = code => {
            $translate.use(code);
        };
        vm.state = $state;

        vm.orderStatus = _.get($location.search(), 'status', 'pending');
        vm.statusText = () => {
            switch(vm.orderStatus) {
                case 'paid':
                    return $state.includes('eventix.download') ? 'common.shop.order.download' : 'common.shop.order.paid';
                default:
                    return 'common.shop.order.' + vm.orderStatus;
            }
        };
    }).filter('isAutomaticProduct', function() {
        return function(input) {
            if(!_.isObject(input))
                return false;
            return /^(Access for|Product for)/.test(_.get(input, 'name', ''));
        };
    }).name;
