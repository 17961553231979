var template = '<ng-transclude></ng-transclude>';

var metaDataTemplate = `<input type="text" placeholder="{{ $ctrl.meta.translateName | customFilterTranslate }}"
       name="{{ $ctrl.meta.name }}"
       ng-if="($ctrl.meta.type == 'integer' || $ctrl.meta.type == 'string') && $ctrl.meta.auto_fill_facebook != 'country'"
       ng-model="$ctrl.value"
       class="metadata-input metadata-guid-{{$ctrl.meta.guid}}"/>
<tx-select other name="{{$ctrl.meta.name}}"
           ng-if="$ctrl.meta.name == 'country'"
           tx-options="c.name as c.name for c in $ctrl.countries"
           ng-model="$ctrl.value"
           class="metadata-input metadata-guid-{{$ctrl.meta.guid}}"></tx-select>
<div ng-if="$ctrl.meta.type == 'date'" class="metadata-input metadata-guid-{{$ctrl.meta.guid}}">
    <div class="visible-xs">{{ $ctrl.meta.translateName | customFilterTranslate}}</div>
    <div class="row">
        <div class="col-xs-3">
            <select ng-model="$ctrl.date.day" ng-options="day for day in $ctrl.days" class="form-control">
                <option value="" translate>common.datetime.day</option>
            </select>
        </div><div class="col-xs-3">
            <select ng-model="$ctrl.date.month" ng-options="m.i as m.name for m in $ctrl.months" class="form-control">
                <option value="" translate>common.datetime.month</option>
            </select>
        </div><div class="col-xs-6">
            <select ng-model="$ctrl.date.year" ng-options="year for year in $ctrl.years" class="form-control">
                <option value="" translate>common.datetime.year</option>
            </select>
        </div>
    </div>
</div>

<tx-select other name="{{ $ctrl.meta.name }}"
           btn-class="form-control"
           placeholder="{{ $ctrl.meta.translateName | customFilterTranslate}}"
           ng-if="$ctrl.meta.type == 'enumOther'"
           ng-model="$ctrl.value"
           tx-options="o for o in $ctrl.options"
           class="metadata-input metadata-guid-{{$ctrl.meta.guid}}"></tx-select>
<select ng-if="$ctrl.meta.type == 'enum' && $ctrl.meta.translatable"
                ng-model="$ctrl.value" class="form-control" name="{{$ctrl.meta.name}}"
                ng-options="'common.metaData.'+$ctrl.meta.name+'_'+option | translate for option in $ctrl.options">
            <option value="">
                {{ 'common.metaData.select_placeholder' | translate }}
                {{ $ctrl.meta.translateName  | translate | lowercase }}
            </option>
        </select>
 <div class="md_dropdown metadata-input metadata-guid-{{$ctrl.meta.guid}}" ng-if="$ctrl.meta.type == 'enum' && !$ctrl.meta.translatable">
     <span>
         <select
                name="{{$ctrl.meta.name}}"
                ng-model="$ctrl.value" 
                ng-options="option for option in $ctrl.options">
            <option value="">
                {{ 'common.metaData.select_placeholder' | customFilterTranslate}}
                {{ $ctrl.meta.translateName  | customFilterTranslate| lowercase }}
            </option>
        </select>
    </span>
</div>
<div ng-if="$ctrl.meta.type == 'boolean'" class="metadata-input metadata-guid-{{$ctrl.meta.guid}}" >
    <label class="control-label checkbox-label custom-checkbox">
        <input type="checkbox" ng-model="$ctrl.value" name="{{$ctrl.meta.name}}" ng-true-value="1" ng-false-value="0">
        {{ $ctrl.meta.translateName | customFilterTranslate}}
    </label>
</div>
<tx-select name="{{ $ctrl.meta.name }}" multiple
           btn-class="form-control"
           placeholder="{{ $ctrl.meta.translateName | customFilterTranslate}}"
           ng-if="$ctrl.meta.type === 'values'"
           ng-model="$ctrl.value"
           tx-options="o for o in $ctrl.options"
           class="metadata-input metadata-guid-{{$ctrl.meta.guid}}"></tx-select>
<p class="explanation metadata-explanation metadata-guid-{{$ctrl.meta.guid}}" ng-show="$ctrl.meta.shop_description.length">
    <i class="fa fa-info-circle"></i> {{ $ctrl.meta.shop_description }}</p>`;

export default angular.module('eventix.common.directives.metadataEdit', [])
    /**
     * @ngdoc directive
     * @name eventix.common.directives:metadataCollection
     * @restrict element
     * @description
     * A container element for metadataEdit components. Monitors the collection of metadata inputs and runs validations on change.
     *
     * @param {Object<guid,string[]>} errors Bi-sync object of errors keyed by metadata guids
     * @param {Object<guid,any>} ngModel By-sync object of values keyed by metadata guids
     */
    .component('customMetadataCollection', {
        controller: CustomMetaDataCollectionController,
        template: template,
        transclude: true,
        bindings: {
            metadata: '<',
            errors: '=?'
        },
        require: {
            ngModel: 'ngModel'
        }
    })
    /**
     * @ngdoc directive
     * @name eventix.common.directives:metadataEdit
     * @restrict element
     * @description
     * Render a correct input element for a metaData
     *
     * @param {MetaData} meta The metadata to render
     * @param {Any} ngModel Where to store the input value
     * @param {Boolean} track Whether to send analytics events when user fills out form
     */
    .component('customMetadataEdit', {
        template: metaDataTemplate,
        controller: CustomMetaDataEditController,
        bindings: {
            meta: '<'
        },
        require: {
            ngModel: 'ngModel'
        }
    }).name;

function CustomMetaDataEditController($translate, $scope, Countries, LaravelValidationRules, $attrs, $analytics) {
    const $ctrl = this;
    $ctrl.days = _.range(1,32);
    $ctrl.years = _.range((new Date()).getYear() + 1900, 1900, -1);
    $ctrl.countries = Countries;

    $ctrl.$onInit = function() {
        $ctrl.track = _.has($attrs, 'track');
        $translate('common.datetime.months').then(months => {
            var range = _.range(1,13);
            months = months.split('_');
            $ctrl.months = _.map(months, (m, i) => {
                return {
                    i: i + 1,
                    name: m
                };
            });
        });
        $scope.$watch('$ctrl.meta', meta => {
            if(!meta) return;
            $ctrl.rules = LaravelValidationRules.parse(meta.extra);
            if(/(enum|values)/.test(meta.type)) {
                let options = _.chain($ctrl.rules).find({name: 'in'}).get('args').value();
                if(!options)
                    options = [''];
                $ctrl.options = options[0].split(',');
            } else if(meta.type === 'date') {
                let isAfter = _.find($ctrl.rules, { name: 'after' });
                let isBefore = _.find($ctrl.rules, { name: 'before' });
                let now = (new Date()).toISOString().slice(0,10);
                let upperBound = parseInt(now.slice(0,4), 10);
                let lowerBound = 1900;
                if(isAfter)
                    lowerBound = parseInt(isAfter.args[0].slice(0,4), 10);
                if(isBefore)
                    upperBound = parseInt(isBefore.args[0].slice(0,4), 10);
                if(upperBound - lowerBound < 1)
                    upperBound = lowerBound + 1;
                if(isAfter || isBefore)
                    $ctrl.years = _.range(upperBound, lowerBound, -1);
            }
            $attrs.$set('metaGuid', meta.guid);
        });
        $scope.$watch('$ctrl.ngModel.$modelValue', modelValue => {
            if(_.get($ctrl, 'meta.type') === 'date') {
                if(!/^\d{4}-\d{2}-\d{2}$/.test(modelValue))
                    return;
                let date = modelValue.split('-');
                $ctrl.date = $ctrl.date || {};
                $ctrl.date.year  = parseInt(date[0], 10);
                $ctrl.date.month = parseInt(date[1], 10);
                $ctrl.date.day   = parseInt(date[2], 10);
            } else
                $ctrl.value = modelValue;
        });

        $scope.$watch('$ctrl.date', date => {
            if(date && _.isNumber(date.day) && _.isNumber(date.month) && _.isNumber(date.year))
                $ctrl.value = [date.year, ('0' + date.month).slice(-2), ('0' + date.day).slice(-2)].join('-');
        }, true);

        $scope.$watch('$ctrl.value', value => {
            if(angular.isUndefined(value)) return;
            $ctrl.ngModel.$setViewValue(value);
            if($ctrl.track) {
                $analytics.eventTrack(null, {
                    category: 'OrderProcess',
                    action: 'Entered metadata',
                    label: $ctrl.meta.translatedName
                });
            }
        }, true);
    };

    $ctrl.autocompleteCity = function(place) {
        $ctrl.value = place.locality;
        $ctrl.dirty = false;
    };
    $ctrl.fallbackCity = function() {
        if($ctrl.dirty)
            $ctrl.value = $ctrl.locality;
    };
}

function CustomMetaDataCollectionController($scope, LaravelValidationRules, MetaData, $element) {
    const $ctrl = this;

    $ctrl.$onInit = function() {
        $ctrl.errors = $ctrl.errors || {};
        $ctrl.touched = {};
        $scope.$watch('$ctrl.ngModel.$modelValue', validate, true);
    };

    $ctrl.$postLink = function() {
        $element.on('focusin', 'input,tx-select,select', isTouched);
        $element.on('blur', 'input,tx-select,select', function(event) {
            validate($ctrl.ngModel.$modelValue);
        });
    };

    function isTouched() {
        let metaGuid = angular.element(this).parents('custom-metadata-edit').attr('meta-guid');
        if(!metaGuid) return;
        $ctrl.touched[metaGuid] = true;
    }

    function validate(metaDataValues) {
        let metaData = _.filter($ctrl.metadata, m => $ctrl.touched[m.guid]);
        $ctrl.errors = LaravelValidationRules.validate(metaData, metaDataValues);
    }
}
