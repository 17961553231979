import template from './personalDetails.html';
import styling from './personalDetails.less';
import customMetaData from './metaDataEdit/metadataEdit.js';

export default angular.module('eventix.shop.personalDetails',[customMetaData])
    .config(function($stateProvider, ) {
        $stateProvider.state('eventix.shop.personalDetails', {
            url: '/personalDetails',
            views: {
                shop: {
                    controller: 'PersonalDetailsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
            }
        });
    })
    .directive('repaint', function($timeout) {
        return {
            restrict: 'C',
            link: function(scope, el, attrs) {
                $timeout(() => el.css({ zIndex: 4 }), 500);
            }
        };
    })
    .controller('PersonalDetailsController', function($state, $scope, shop, order, products, tickets, LaravelValidationRules, $filter,
        Rollbar, FB, $q, UIMessages, VisitorData, $analytics, OAuthTokens, MetaData, ShopAnalytics) {
        var vm = this;
        if(_.flatten(_.values(order.reservations)).length < 1)
            $state.go('eventix.shop.events', {shopId: shop.guid});
        if(order.receiver.email || FB.disabled) // Don't show facebook login button if profile is already filled
            vm.manualEntry = true;

        vm.manualEntry = true;
        vm.order = order;
        vm.shop = shop;
        vm.tickets = tickets;
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto')

        if (order.hasInvalidReservations()) {
            $state.go('eventix.shop.lockerAndCo2', {shopId: shop.guid});
            return;
        }

        vm.hasVerzendProduct = vm.order.globalProductReservations.filter(g => g.product_guid === 'a3aad8d5-8458-459f-af59-959a8166b855').length;

        vm.shopMetadata = vm.order.shopMetaData;
        vm.optionalVerzendMetaDataGuids = ['0dc1d821-312a-4eb2-bd3a-eddc6eae52fd','63cb997c-3bd0-41ee-8c78-176df2063b98','d2403bc9-2b3f-45a0-9075-2f425c72c6be','e7b13f33-d40e-4276-8bb7-c52f79b5b095', '8b9b8dcb-6dbe-46e6-8f0a-be510121513c'];
        if(!vm.hasVerzendProduct){
            // make sure remove old entries or else it fails
            _.forEach(vm.optionalVerzendMetaDataGuids, function(guid){
                delete vm.order.receiverMeta[guid];
            });

            vm.shopMetadata = _.filter(vm.order.shopMetaData, function(md){
                if(vm.optionalVerzendMetaDataGuids.includes(md.guid)) {
                    // remove requiredness
                    if (md.extra && (md.extra).includes('required') && md.type !== 'boolean') {
                        md.extra = md.extra.replace('required', '||');
                    }
                    md.extra = fixMDExtra(md.extra);
                }
                return !vm.optionalVerzendMetaDataGuids.includes(md.guid);
            });
        }
        if(vm.hasVerzendProduct){
            vm.shopMetadata = _.filter(vm.order.shopMetaData, function(md){
                if(md.type !== 'boolean'){
                    if(!md.extra){
                        md.extra = '|required|'; // add frontend requiredness
                    }
                    else{
                        md.extra += '|required|'; // add frontend requiredness
                    }
                }
                md.extra = fixMDExtra(md.extra);
                return true;
            });
        }

        function fixMDExtra(extra){
            return LaravelValidationRules.stringify(_.filter( LaravelValidationRules.parse(extra)));
        }

        vm.products = products;
        vm.verifyEmail = function(error, valid, suggestion) {
            if(!suggestion)
                return;
            if(!_.get(vm.order.receiver,'$errors.email'))
                _.set(vm.order.receiver, '$errors.email', []);
            vm.order.receiver.$errors.email.push($filter('translate')('common.notice.didYouMean', {suggestion: suggestion }));
        };

        vm.back = () => {
            if(!vm.fourStepWizard)
                return $state.go('eventix.shop.merchandise', { shopId: shop.guid });
            const eventId = _.findKey(order.tickets, tickets => {
                return _.some(tickets, { guid: order.firstReservation.ticket.guid });
            });
            return $state.go('eventix.shop.merchandise', { shopId: shop.guid, eventId });
        };
        vm.next = () => {
            let invalids = order.validateReceiver();
            if(invalids.length === 0) {
                $state.go('eventix.shop.summary',{ shopId: shop.guid });
            }
            if(angular.isString(invalids)){
                UIMessages.push(invalids);
            }
            if(Array.isArray(invalids)){
                _.forEach(invalids, function(invalid){
                    UIMessages.push(invalid);
                });
            }
        };

        ShopAnalytics.checkoutStepOne();
        /*
        * We keep tabs on whether people understand the accordion.
        * If they have unopened tabs, goto that tab when you press "Next"
        */
        vm.hasOpened = { };
        _.forEach(vm.order.reservations, (ticketReservations, ticketGuid) => {
            if (!vm.order.showTicketReservationForm(ticketGuid))
                return;
            _.forEach(ticketReservations, reservation => {
                vm.hasOpened[reservation.reservation] = false;
            });
        });
        vm.logOpeningCollapse = function(reservationGuid) {
            vm.hasOpened[reservationGuid] = true;
        };

        vm.facebookLogin = function() {
            order.loginWithFacebook().catch(() => {
                UIMessages.push('common.shop.facebookError');
                return $q.resolve();
            }).then(() => {
                vm.manualEntry = true;
            });
        };
    }).component('customReceiverMetadata', {
        bindings: { data: '=', metaData: '=', errors: '=' , inputWidth: '='},
        controller: function($scope) {
            const $ctrl = this;
        },
        template: `<custom-metadata-collection ng-model="$ctrl.data" errors="$ctrl.errors" metadata="$ctrl.metaData">
                        <div class="md_form-group force-inline-block {{$ctrl.errors[meta.guid].length ? 'has-input-errors' : ''}}"  ng-repeat="meta in $ctrl.metaData">
                            <label class="md_label" ng-if="!(meta.type === 'boolean')">{{ (meta.type === 'boolean' ? '' : meta.translateName) | customFilterTranslate}}{{ (meta.extra | includes:'required') ? '*' : ''}}</label>
                            <custom-metadata-edit ng-model="$ctrl.data[meta.guid]" meta="meta"></custom-metadata-edit>
                        </div>
                    </custom-metadata-collection>`
    }).name;
