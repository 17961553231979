import template from './lockerAndCo2.html';
import styling from './lockerAndCo2.less';

export default angular.module('eventix.shop.lockerAndCo2',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.lockerAndCo2', {
            url: '/lockerAndCo2',
            views: {
                shop: {
                    controller: 'LockerAndCo2Controller as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
            }
        });
    })
    .directive('repaint', function($timeout) {
        return {
            restrict: 'C',
            link: function(scope, el, attrs) {
                $timeout(() => el.css({ zIndex: 4 }), 500);
            }
        };
    })
    .controller('LockerAndCo2Controller', function($state, $scope, shop, order, products, tickets, LaravelValidationRules, $filter,
        Rollbar, FB, $q, UIMessages, VisitorData, $analytics, OAuthTokens, MetaData, ShopAnalytics, Ticket, $rootScope, $window) {

        $rootScope.pageTitle = 'Tickets';

        /// Make sure to scroll to top
        $window.scrollTo(0, 0);

        var vm = this;
        vm.order = order;
        vm.ticketReservations = vm.order.reservations;
        vm.shop = shop;
        vm.tickets = tickets;
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');

        // if(_.flatten(_.values(order.reservations)).length < 1) {
        //     $state.go('eventix.shop.events', {shopId: shop.guid});
        // }

        // Keep track of reservations
        vm.currentReservation = null;
        vm.nextReservations = [];
        vm.previousReservations = [];

        vm.applicableReservations = [];
        vm.hasOptionalPick = false;
        let first = true;
        _.forEach(vm.order.reservations, function(reservations, ticketGuid){
            // vm.applicableReservations = vm.applicableReservations.concat(reservations);
            _.forEach(reservations, function (reservation){
                if(vm.order.showTicketReservationForm(reservation.ticket_guid)) {
                    vm.applicableReservations.push(reservation);

                    vm.hasOptionalPick = vm.hasOptionalPick || vm.order.showTicketReservationForm(reservation.ticket_guid);
                    if (first) {
                        vm.currentReservation = reservation;
                    }
                    if (!first) {
                        vm.nextReservations.push(reservation);
                    }
                    first = false;
                }
            });
        });

        vm.totalTickets = vm.applicableReservations.length;
        vm.currentTicket = 1;

        function checkIfCurrentReservationIsValid(){
            let invalid =  vm.currentReservation.$invalid();
            if(angular.isString(invalid)){
                if(((vm.currentReservation.ticket.name).toLowerCase()).includes('bus')){
                    UIMessages.push("Let op: het is verplicht om een bus te selecteren. Lees meer informatie op de eerste pagina tijdens het selecteren van jouw ticket.");
                }
                else {
                    UIMessages.push(invalid);
                }

                return false; // stay on same reservation with error handling
            }
            return true;
        }

        function getNextReservation() {
            // first check if current is valid
           if(!checkIfCurrentReservationIsValid()){
               return true; // stay on same reservation
           }

            if(vm.nextReservations.length > 0){
                vm.previousReservations.push(vm.currentReservation);
                vm.currentReservation = vm.nextReservations.shift();
                vm.currentTicket++;
                $window.scrollTo(0, 0);
                return true;
            }
            return false;
        }

        function getPreviousReservation() {
            if(vm.previousReservations.length > 0){
                vm.nextReservations.unshift(vm.currentReservation);
                vm.currentReservation = vm.previousReservations.pop();
                vm.currentTicket--;
                $window.scrollTo(0, 0);
                return true;
            }
            return false;
        }

        vm.back = () => {
            // if previousReservations is not empty, show first;
            // if previousReservations is empty, continue;
            if(getPreviousReservation()){
                return; // continue;
            }

            if(!vm.fourStepWizard)
                return $state.go('eventix.shop.tickets', { shopId: shop.guid });
            const eventId = _.findKey(order.tickets, tickets => {
                return _.some(tickets, { guid: order.firstReservation.ticket.guid });
            });
            return $state.go('eventix.shop.tickets', { shopId: shop.guid, eventId });
        };
        vm.next = () => {
            if(vm.applicableReservations.length == 0){
                $state.go('eventix.shop.merchandise', {shopId: shop.guid});
            }

            // Check current reservation if valid, set to next
            // if nextReservations is empty, continue;
            if(getNextReservation()){
                return; // continue;
            }

            let invalid = order.hasInvalidReservations();
            if(!invalid) {
                $state.go('eventix.shop.merchandise', {shopId: shop.guid});
                // $state.go('eventix.shop.merchandise', {shopId: shop.guid});
            }
            if(angular.isString(invalid)){
                UIMessages.push(invalid);
                return false; // stay on same reservation with error handling
            }
        };

        ShopAnalytics.checkoutStepOne();
    }).name;






