import template from './download.html';
import header from '../header.html';
import './download.less';
import sweetAlert from 'angular-sweetalert';
import sweetAlertCss from 'sweetalert/lib/sweet-alert.css';

export default angular.module('eventix.order.download', ['oitozero.ngSweetAlert'])
    .config(function($stateProvider, GoogleTagManagerProvider) {
        $stateProvider.state('eventix.download', {
            url: '/download/:guid?order_ticket_id',
            views: {
                main: {
                    controller: 'OrderDownloadController',
                    controllerAs: 'vm',
                    templateUrl: template
                },
                header: {
                    controller: 'OrderStatusHeaderController',
                    controllerAs: 'vm',
                    templateUrl: header
                }
            },
            resolve: /*@ngInject*/{
                order: function(Order, $transition$) {
                    return Order.getPublic($transition$.params().guid).then(order => {
                        // Load Tag as early as possisble
                        if(order.shop.google_tag)
                            GoogleTagManagerProvider.addContainer(order.shop.google_tag);
                        return order;
                    });
                },
                shop: function($http, AskAPI, Shop, Currencies, order) {
                    let shopId = order.shop_id;
                    let url = '/' + shopId + '/data';
                    if(typeof USE_CACHED !== 'undefined' && USE_CACHED == 1){
                        url = 'https://ev3b.s3-eu-west-1.amazonaws.com/shops/' + shopId + '/' + shopId + '.json';
                    }
                    return $http.get(url)
                        .then(response => {
                            return AskAPI.restore(response);
                        }).then(() => {
                            let shop = Shop.cached.first();
                            Currencies.use(shop.currency);
                            return shop;
                        });
                }
            }
        });
    })
    .controller('OrderDownloadController', function($state, $scope, $q, order, shop, LaravelValidationRules, $timeout, UIMessages, $window, VisitorData, MetaData, $http, Order, Company, Whitelabel, SweetAlert) {
        const vm = this;
        vm.messages = UIMessages;
        vm.order = order;
        vm.shopLink = SHOP_ROOT + '/' + order.shop_id;
        vm.closedLoopUrl = 'https://browser.ticketswap.com/sell/tickets/eventix/' + order.guid;
        vm.shop = order.shop;
        vm.isMobile = /mobile/i.test(_.get(VisitorData, 'userAgent.device.type', ''));
        vm.isApple = /mac|ios/i.test(_.get(VisitorData, 'userAgent.os.name', ''));
        vm.downloadReady = false;
        vm.status = 'pending';
        vm.supportValues = {
            email: $scope.branding.email || 'tickets@eventix.nl'
        };
        vm.cancel = (e) => e.preventDefault() && e.stopPropagation();
        vm.currency = _.get(vm.payment, 'currency', DEFAULT_CURRENCY);
        vm.orderIsRetrievable = true;
        vm.company = Company.cached.first();
        vm.whitelabel = Whitelabel.cached.first();

        vm.forOrderTicketId = _.get($state.params, 'order_ticket_id');
        vm.currentTicket;

        vm.metadata = MetaData.cached;
        vm.remembers = {};

        (function poll() {
            fetchOrder();
        })();

        vm.startPersonalisation = startPersonalisation;
        function startPersonalisation(){
            let collapses = _.filter($scope.accordion.groups, function(g){
                if((g.panelClass).includes('merchandise'))
                    return false;
                return true;
            });

            _.first(collapses).toggleOpen();
        }

        function completeOrderInfo(order){
            vm.order = order;
            vm.status = order.status;
            vm.downloadReady = order.download_ready;
            let timestamp = Math.floor(Date.now() / 1000);

            if (!order.tickets)
                return;

            let isOrderRetrievable = true;

            order.tickets = _.map(order.tickets, ticket => {
                ticket.metadataValues = {};
                _.forEach(ticket.metadata ,function(md){
                    ticket.metadataValues[md.metadata_id] = md.value;
                });
                ticket.errors = {};
                ticket.isopen = false;
                ticket.has_details = true;
                ticket.originalMetadata = ticket.metadata;
                ticket.acceptTerms = false;
                ticket.canSend = true;

                if(vm.remembers.hasOwnProperty(ticket.guid)){
                    ticket.canSend = false;
                    ticket.has_details = vm.remembers[ticket.guid].has_details;
                    updateTicketMetaData(ticket, false);
                }

                ticket.isRetrievable = true;
                if (ticket.retrievable_after && timestamp < ticket.retrievable_after) {
                    ticket.isRetrievable = false;
                    isOrderRetrievable = false;
                    ticket.retrievable_after_readable = moment.unix(ticket.retrievable_after).format("YYYY-MM-DD HH:mm");
                }

                ticket.canBeDownloaded = ticket.is_complete && _.isNil(ticket.invalidated_since);

                if(ticket.guid === vm.forOrderTicketId){
                    vm.closedLoopUrl = vm.closedLoopUrl + '?tickets[]='+vm.forOrderTicketId;
                    vm.currentTicket = ticket;
                }

                return ticket;
            });

            vm.order.merchandise = _.filter(vm.order.products, function(p){
                 if(p.product.guid !== '1622eb68-7fbd-4945-892e-b4202129b571'){
                     return true;
                 }
                 return false;
            });


            if(isOrderRetrievable === false){
                vm.orderIsRetrievable = false;
            }

            vm.order.canBeDownloaded = vm.order.is_complete && _.sumBy(order.tickets, 'canBeDownloaded') ==_.size(order.tickets);
            vm.order.isRetrievable = isOrderRetrievable;
            vm.order.retrievable_after = _.get(_.maxBy(order.tickets, 'retrievable_after'), 'retrievable_after', null);
            vm.order.retrievable_after_readable = moment.unix(order.retrievable_after).format("YYYY-MM-DD HH:mm");
        }

        vm.updateTicketMetaData = updateTicketMetaData;
        function updateTicketMetaData(ticket, sendable){
            if(sendable){
                ticket.canSend = true;
            }
            if(ticket.has_details){
                // all metadata
                ticket.metadata = ticket.originalMetadata;
            }else{
                // firstname / lastname / email
                let filteredMetaData = _.filter(ticket.originalMetadata, function(md){
                    if(md.metadata.name === 'first_name' || md.metadata.name === 'last_name' || md.metadata.name === 'email'){
                        return true;
                    }
                    return false;
                });
                ticket.metadata = filteredMetaData;
            }
        }

        vm.saveTicket = saveTicket;
        function saveTicket(ticket, sendMail){
            if(!ticket.acceptTerms && ticket.has_details){
                if((vm.shop.company_terms || vm.shop.global_terms) && !vm.acceptTerms)
                    return UIMessages.push('common.notice.acceptTerms');
            }

            let validateMetaData = {};
            _.forEach(ticket.metadata, function(tmd){
                validateMetaData[tmd.metadata_id] = _.get(vm.metadata, tmd.metadata_id);
            });
            let errors = LaravelValidationRules.validate(_.filter(validateMetaData), ticket.metadataValues);

            if(_.some(errors, 'length')){
                _.forEach(errors, function(error){
                    _.forEach(error, function(msg){
                        UIMessages.push(msg);
                    });
                });
                return;
            }

            let ticketMetaDataValues = _.keyBy(ticket.metadata, 'metadata_id')
            let dirties = _.filter(_.map(ticket.metadataValues, (value, guid) => {
                    let ticketMd = ticketMetaDataValues[guid];

                    if(ticketMd) {
                        if (value === ticketMd.value) {
                            return null;
                        }

                        return {
                            order_metadata_id: ticketMd.guid,
                            metadata_id: guid,
                            value: value
                        };
                    }
            }));

            vm.busy = true;

            let data = {values: dirties, send_email: sendMail};

            $http.put(`/personalize/ticket/${ticket.guid}`, data)
                .then((response) => {
                    vm.remembers[ticket.guid] = ticket;
                    if(sendMail){
                        SweetAlert.swal({
                            title: "Uitnodiging verstuurd",
                            text: '',
                            type: 'success',
                            timer: 1500,
                            showConfirmButton: false
                        });
                    }
                    else{
                        SweetAlert.swal({
                            title: "Ticket gepersonaliseerd",
                            text: '',
                            type: 'success',
                            timer: 1500,
                            showConfirmButton: false
                        });
                    }

                    vm.busy = false;
                    fetchOrder();
                    $scope.$evalAsync();
                    return;
                }, error => {
                    vm.busy = false;
                    UIMessages.push((error.data.error_description ? error.data.error_description : 'Error'));
                    return;
                });
        }

        function fetchOrder(){
            return $http.get(ORDER_API_ROOT + `/order/${ vm.order.guid }`)
                .then(res => Order.new(res.data))
                .then(order => {
                    completeOrderInfo(order);
                })
                .catch(error => {
                    if (error.message === 'processing') {
                        vm.status = 'pending';
                        completeOrderInfo(order);
                        $timeout(poll, 5000);
                    } else if(error.message === 'cancelled') {
                        vm.status = 'cancelled';
                        UIMessages.push('common.shop.order.status_cancelled');
                    } else if(_.isString(error.message)) {
                        UIMessages.push(error.message);
                    } else if(_.isString(error)) {
                        UIMessages.push(error);
                    } else {
                        UIMessages.push('common.notice.error');
                    }
                });
        }

    }).name;
