import template from './busses.html';
import seatsTemplate from '../tickets/seats/seats.html';

export default angular.module('eventix.shop.busses', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.busses', {
            url: '/busses',
            views: {
                'shop': {
                    controller: 'BussesController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('BussesController', function(UIMessages, shop, order, collapses, $state, $window,
                                              $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce,
                                              $analytics, FB, $timeout, store, $filter, $location, SeatsIO, ShopAnalytics) {
        var vm = this;
        vm.seatSelection = {
            templateUrl: seatsTemplate,
            open: false
        };
        vm.order = order;
        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;
        vm.exclusions = [
            "91a7f0ae-0245-49f4-a780-ccc376d421ed",
            "c01585f7-fcc4-4c93-83bd-23626a4b5a11",
            "4986ad3c-322d-40dc-92c7-640261d56280",
            "eafdc68c-7bda-4c83-9396-95e471ae7f2e",
            "69bf256a-b355-49bd-898e-0fa4dc38c5d2",
            "c5faaf1a-4bb9-447f-84da-f2a4fa51fd2e",
            "0945c002-c5cf-4425-b927-e9877fa16800",
            "c67a659a-ab9d-4023-a1e9-194c0467c5aa",
            "15f02b40-ca34-450d-8f23-499c38955c96",
            "3a50c5ae-ab14-4c35-8692-5da69cef3bd2",
            "0dda2dd6-2529-44fe-acb1-441cad939c49",
            "35f8be13-8993-495a-840e-4b2d78c8a66d",
            "e7f65195-f017-4db9-a5ad-6972e43e25cd",
            "d05347d2-a34f-471e-a341-e144aeb497c9",
            "33350963-c2ba-4bb4-81d6-6851052d9f45",
            "0e0aa951-e6a6-4d45-9b6b-15e1849ea8ee"
        ]
        vm.tickets = {"16fdc11d-50a5-444a-8b61-1cb321a74c03": order.tickets["16fdc11d-50a5-444a-8b61-1cb321a74c03"]}
        // vm.tickets["16fdc11d-50a5-444a-8b61-1cb321a74c03"] = [];
        vm.events = order.events;
        vm.products = order.products;
        vm.collapses = collapses;
        vm.eventFilter = $state.params.eventId;

        let queryParams = $location.search();
        if (queryParams.coupon)
            vm.order.applyCoupon(queryParams.coupon);

        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        // if(vm.fourStepWizard && !vm.eventFilter)
        //     $state.go('eventix.shop.events', { shopId: vm.shop.guid });

        if(vm.fourStepWizard && vm.eventFilter)
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        else if(!vm.fourStepWizard)
            ShopAnalytics.impression();

        /**
         * Close seat selection dialog
         */
        vm.closeSeatSelection = function() {
            vm.seatSelection.open = false;
        };

        vm.openSeatSelection = function(ticket, eventId) {
            SeatsIO.open(ticket, eventId).then(() => vm.seatSelection.open = true);
        };

        vm.back = () => {
            if(!vm.fourStepWizard)
                return $state.go('eventix.shop.lockersAndCo2', { shopId: shop.guid });
            const eventId = _.findKey(order.tickets, tickets => {
                return _.some(tickets, { guid: order.firstReservation.ticket.guid });
            });
            return $state.go('eventix.shop.lockersAndCo2', { shopId: shop.guid, eventId });
        };
        vm.next = () => {
            let invalid = order.hasInvalidReservations();
            if(!invalid) {
                $state.go('eventix.shop.merchandise', {shopId: shop.guid});
            }
            if(angular.isString(invalid)){
                UIMessages.push(invalid);
                return false; // stay on same reservation with error handling
            }
        };

        vm.reserve = function(ticketGuid, eventId){
            vm.order.reserveTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.release = function(ticketGuid, eventId){
            vm.order.releaseTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.removeFromCart(ticket);
        };
    }).name;
